<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="4">
        <el-card shadow="always">
          <div class="clearfix">
            <h4>
              Нийт хүргэлтийн ажилтан :
              {{ this.driversByStatus.riders }}
            </h4>
          </div>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card shadow="always">
          <div class="clearfix">
            <h4>
              Бүртгэл баталгаажсан хүргэлтийн ажилтан :
              {{ this.driversByStatus.verifiedDrivers }}
            </h4>
          </div>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card shadow="always">
          <div class="clearfix">
            <h4>
              HR баталгаажуулсан хүргэлтийн ажилтан :
              {{ this.driversByStatus.verifiedByHrDrivers }}
            </h4>
          </div>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card shadow="always">
          <div class="clearfix">
            <h4>
              Идэвхитэй хүргэлтийн ажилтан :
              {{ this.driversByStatus.activeDrivers }}
            </h4>
          </div>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card shadow="always">
          <div class="clearfix">
            <h4>
              Идэвхигүй хүргэлтийн ажилтан :
              {{ this.driversByStatus.inActiveDrivers }}
            </h4>
          </div>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card shadow="always">
          <div class="clearfix">
            <h4 style="color:red">
              Баталгаажаагүй хүргэлтийн ажилтан :
              {{ this.driversByStatus.notVerifiedDrivers }}
            </h4>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="mt20">
      <el-col :span="20">
        <div class="page-header panel">
          <h3 class="title">Жолоочийн жагсаалт</h3>
          <div class="action-section">
            <router-link to="/driver-add">
              <el-button
                type="success"
                size="mini"
                icon="el-icon-plus"
                v-if="!mArrayIncludes(role, ['CC', 'TL', 'driverShow'])"
                >Нэмэх</el-button
              >
            </router-link>
          </div>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="page-header panel">
          <el-popover
            placement="right"
            width="250"
            trigger="click"
            style="padding-right: 60%"
          >
            <el-table :data="this.driversByStatus.driversByGroup">
              <el-table-column
                width="120"
                property="driver_group_name"
                label="Багийн нэр"
              ></el-table-column>
              <el-table-column
                width="45"
                property="count"
                label="Тоо"
              ></el-table-column>
            </el-table>
            <el-button
              type="success"
              slot="reference"
              size="mini"
              :disabled="mArrayIncludes(role, ['driverShow'])"
              >Багийн мэдээлэл</el-button
            >
          </el-popover>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" :offset="0">
        <div class="panel">
          <el-form
            :inline="true"
            class="demo-form-inline"
            size="mini"
            style="margin-top: 20px"
          >
            <el-row :gutter="20">
              <el-col :span="21">
                <el-form-item>
                  <el-date-picker
                    v-model="filterPayload.start_date"
                    type="datetime"
                    placeholder="Эхлэх хугацаа"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-date-picker
                    v-model="filterPayload.end_date"
                    type="datetime"
                    placeholder="Дуусах хугацаа"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-input
                    @keyup.enter.native="getDrivers"
                    style="width: 200px"
                    class="input-with-select"
                    prefix-icon="el-icon-search"
                    v-model="filterPayload.search"
                    clearable
                    size="mini"
                    placeholder="Хуучин залгасан нэр"
                  />
                </el-form-item>
                <el-form-item>
                  <el-input
                    @keyup.enter.native="getDrivers"
                    style="width: 200px"
                    class="input-with-select"
                    prefix-icon="el-icon-search"
                    v-model="filterPayload.first_name"
                    clearable
                    size="mini"
                    placeholder="Нэр"
                  />
                </el-form-item>
                <el-form-item>
                  <el-input
                    @keyup.enter.native="getDrivers"
                    style="width: 200px"
                    class="input-with-select"
                    prefix-icon="el-icon-search"
                    v-model="filterPayload.last_name"
                    clearable
                    size="mini"
                    placeholder="Овог"
                  />
                </el-form-item>
                <el-form-item>
                  <el-input
                    @keyup.enter.native="getDrivers"
                    style="width: 200px"
                    class="input-with-select"
                    prefix-icon="el-icon-search"
                    v-model="filterPayload.phone"
                    clearable
                    size="mini"
                    placeholder="Утасны дугаар"
                  />
                </el-form-item>
                <el-form-item>
                  <el-input
                    @keyup.enter.native="getDrivers"
                    style="width: 200px"
                    class="input-with-select"
                    prefix-icon="el-icon-search"
                    v-model="filterPayload.register_number"
                    clearable
                    size="mini"
                    placeholder="Регистрийн дугаар"
                  />
                </el-form-item>
                <el-form-item>
                  <el-input
                    @keyup.enter.native="getDrivers"
                    style="width: 200px"
                    class="input-with-select"
                    prefix-icon="el-icon-search"
                    v-model="filterPayload.email"
                    clearable
                    size="mini"
                    placeholder="И-мэйл хаяг"
                  />
                </el-form-item>
                <el-form-item>
                  <el-select
                    clearable
                    style="width: 200px"
                    v-model="filterPayload.driver_group_name"
                    placeholder="Жолоочийн баг сонгох"
                  >
                    <el-option
                      v-for="(team, indexTeam) in driverListTeam"
                      :key="indexTeam"
                      :label="team.group_name"
                      :value="team.group_name"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-select
                    clearable
                    style="width: 200px"
                    v-model="filterPayload.status"
                    placeholder="Ажлын төлөв"
                  >
                    <el-option
                      v-for="(status, indexStatus) in statusFilter"
                      :key="indexStatus"
                      :label="status.label"
                      :value="status.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-select
                    clearable
                    style="width: 200px"
                    v-model="filterPayload.is_company_employee"
                    placeholder="Компанийн ажилтан эсэх"
                  >
                    <el-option
                      v-for="(type, indexType) in isEmployee"
                      :key="indexType"
                      :label="type.label"
                      :value="type.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-select
                    clearable
                    style="width: 200px"
                    v-model="filterPayload.is_hr_verified"
                    :disabled="mArrayIncludes(role, ['driverShow'])"
                    placeholder="Хүний нөөц зөвшөөрсөн эсэх"
                  >
                    <el-option
                      v-for="(type, indexType) in isHrVer"
                      :key="indexType"
                      :label="type.label"
                      :value="type.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-select
                    clearable
                    style="width: 200px"
                    v-model="filterPayload.is_disabled"
                    placeholder="Идэвхгүй болсон эсэх"
                    :disabled="mArrayIncludes(role, ['driverShow'])"
                  >
                    <el-option
                      v-for="(type, indexType) in isEmployee"
                      :key="indexType"
                      :label="type.label"
                      :value="type.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button @click="searchFilter" type="primary"
                    ><i class="el-icon-search mr5"></i>Хайх</el-button
                  >
                </el-form-item>
                <el-form-item v-if="mArrayIncludes(role, ['driverShow'])">
                  <el-button @click="download" type="success"
                    ><i class="el-icon-download mr5"></i>Татах</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-table
            size="mini"
            :data="driverList"
            v-loading="isDataLoading"
            style="width: 100%"
          >
            <el-table-column prop="id" width="50"> </el-table-column>
            <el-table-column
              width="150px"
              prop="created_at"
              label="Бүртгэсэн огноо"
              sortable
            ></el-table-column>
            <el-table-column
              width="130px"
              prop="name"
              label="Хуучин нэр"
              sortable
            ></el-table-column>
            <el-table-column
              width="130px"
              prop="first_name"
              label="Нэр"
              sortable
            ></el-table-column>
            <el-table-column
              prop="last_name"
              label="Овог"
              width="120px"
            ></el-table-column>
            <el-table-column width="120px" prop="register_number" label="РД">
            </el-table-column>
            <el-table-column prop="phone" label="Утас"></el-table-column>
            <el-table-column
              prop="driver_group_name"
              label="Баг"
              sortable
            ></el-table-column>
            <el-table-column
              width="150px"
              prop="last_order_date"
              label="Сүүлийн хүргэлт"
              sortable
            >
            </el-table-column>
            <el-table-column
              prop="etax_user_name"
              label="E-tax"
            ></el-table-column>
            <el-table-column
              width="120px"
              prop="etax_pass"
              label="E-tax нууц үг"
            ></el-table-column>
            <el-table-column
              width="150px"
              prop="phone"
              label="Цүнх авсан мэдээлэл"
            >
              <template slot-scope="scope">
                <el-tag
                  v-if="scope.row.is_bag !== null"
                  :type="scope.row.is_bag === 1 ? 'success' : 'danger'"
                  ><span style="font-size: 20px"
                    ><i
                      :class="
                        scope.row.is_bag === 1
                          ? 'el-icon-success'
                          : 'el-icon-circle-close'
                      "
                    ></i></span
                ></el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="Төлөв">
              <template slot-scope="scope">
                <el-tag
                  size="mini"
                  v-if="scope.row.status == 'active'"
                  type="success"
                  effect="dark"
                  @click="showEdit(scope.row)"
                >
                  Онлайн
                </el-tag>
                <el-tag
                  size="mini"
                  v-else-if="scope.row.status == 'inactive'"
                  type="info"
                  effect="dark"
                  @click="showEdit(scope.row)"
                  >Офлайн</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column prop="zip" label="HR" fixed="right">
              <template slot-scope="scope">
                <el-switch
                  :disabled="mArrayIncludes(role, ['driverShow'])"
                  v-model="scope.row.is_hr_verified"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @change="changeHrVerify(scope.row, 'hr')"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column prop="zip" label="Active" fixed="right">
              <template slot-scope="scope">
                <el-switch
                  :disabled="mArrayIncludes(role, ['driverShow'])"
                  v-model="scope.row.is_disabled"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @change="changeHrVerify(scope.row, 'disable')"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column prop="zip" label="Үйлдэл" fixed="right">
              <template slot-scope="scope">
                <el-button
                  :disabled="mArrayIncludes(role, ['CC', 'TL', 'driverShow'])"
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  @click="getDriverById(scope.row.firebase_uid)"
                  >Засах</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="text-right"
            background
            @size-change="sizeChange"
            @current-change="setPage"
            :current-page.sync="currentPage"
            :page-sizes="[10, 20, 50, 100, 150, 200]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next"
            :total="pageCounts"
          >
          </el-pagination>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      :title="'Жолоочийн нэр : ' + driveEditState.name + ' '"
      :visible.sync="dialogVisible"
      @close="closeProgress()"
      width="20%"
      top="100px"
      class="process-dialog"
    >
      <div class="panel">
        <template>
          <h3>Жолоочийн төлөв өөрчлөх</h3>
          <el-button
            type="success"
            v-model="driveStatus.status"
            @click="getDriveStatus('active')"
            icon="el-icon-check"
            round
            >Онлайн</el-button
          >
          <el-button
            type="danger"
            v-model="driveStatus.status"
            @click="getDriveStatus('inactive')"
            icon="el-icon-check"
            round
            >Офлайн</el-button
          >
        </template>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import service from "@/helpers/services.js";
import { arrayIncludes } from "../helpers/helper";
import axios from "axios";
import { getGroups } from "../utils/auth";
import XLSX from "xlsx";
import * as dayjs from "dayjs";
export default {
  name: "DriverList",
  async created() {
    this.role = await getGroups();
    this.filterPayload.is_hr_verified = this.mArrayIncludes(this.role, [
      "driverShow"
    ])
      ? 1
      : null;
    this.filterPayload.is_disabled = this.mArrayIncludes(this.role, [
      "driverShow"
    ])
      ? 0
      : null;
    this.getDrivers();
    this.getDriverTeamList();
  },
  mounted() {
    this.getDriversByStatus();
  },
  data() {
    return {
      filterPayload: {
        search: "",
        phone: "",
        first_name: "",
        last_name: "",
        register_number: "",
        email: "",
        status: null,
        is_disabled: null,
        driver_group_name: null,
        is_company_employee: null,
        is_hr_verified: null,
        start_date: "",
        end_date: "",
        download: false
      },
      isDataLoading: false,
      driverList: [],
      driverListTeam: null,
      inActivedriverList: [],
      selectStatus: "",
      pageCounts: 0,
      currentPage: 1,
      pageSize: 50,
      page: 1,
      driveEditState: {},
      driversByStatus: [],
      drivers: [],
      dialogVisible: false,
      driveStatus: {
        firebase_uid: "",
        status: ""
      },
      disabled: 0,
      role: [],
      timer: null,
      typeStatus: [
        {
          value: 1,
          label: "Идэвхтэй"
        },
        {
          value: 0,
          label: "Идэвхгүй"
        }
      ],
      statusFilter: [
        {
          label: "Online",
          value: "active"
        },
        {
          label: "Offline",
          value: "inactive"
        }
      ],
      isEmployee: [
        {
          value: 1,
          label: "Тийм"
        },
        {
          value: 0,
          label: "Үгүй"
        }
      ],
      isHrVer: [
        {
          value: 1,
          label: "Тийм"
        },
        {
          value: 0,
          label: "Үгүй"
        }
      ]
    };
  },
  methods: {
    getDriverTeamList() {
      service.getDriverTeamList().then(response => {
        if (response.status == "success") {
          this.driverListTeam = response.data;
        } else {
          this.$notify({
            title: "Алдаа",
            message: response.message,
            type: "warning"
          });
        }
      });
    },
    changeHrVerify(data, type) {
      let payload = {};
      if (type === "disable") {
        payload = {
          is_disabled: data.is_disabled ? 0 : 1,
          user_uid: data.firebase_uid
        };
      } else if (type === "hr") {
        payload = {
          is_hr_verified: data.is_hr_verified ? 1 : 0,
          user_uid: data.firebase_uid
        };
      }
      axios
        .post(
          process.env.VUE_APP_DRIVER_API + "admin/driver/updateUserByUid",
          payload
        )
        .then(response => {
          if (response.data.status === "success") {
            this.$notify({
              title: "Амжилттай",
              type: "success"
            });
            this.getDrivers();
          } else if (response.data.status === "unsuccess") {
            this.$notify({
              title: "Амжилтгүй",
              type: "warning"
            });
          }
        })
        .catch(error => {
          this.$notify.error({
            title: "Уучлаарай",
            position: "bottom-left",
            message: error.message,
            duration: 2000
          });
        });
    },
    getDriveStatus(value) {
      this.$confirm(
        "Та жолоочийн төлөв өөрчлөхдөө итгэлтэй байна уу?",
        "Анхаар",
        {
          confirmButtonText: "Тийм",
          cancelButtonText: "Буцах",
          type: "warning"
        }
      ).then(() => {
        const payload = {
          firebase_uid: this.driveEditState.firebase_uid,
          status: value
        };
        axios
          .post(
            process.env.VUE_APP_DRIVER_API + "admin/driver/setDriverStatus",
            payload
          )
          .then(response => {
            if (response.data.status == "success") {
              this.getDrivers();
              this.$notify({
                title: "Амжилттай",
                message: "Жолооч төлөв өөрчлөгдлөө",
                type: "success"
              });
              this.closeProgress();
            } else {
              this.$notify({
                title: "Жолоочийн төлөв өөрчлөхөд алдаа гарлаа",
                message: response.data.error,
                type: "warning"
              });
            }
          });
      });
    },
    closeProgress() {
      this.driveEditState = {};
      this.dialogVisible = false;
    },
    showEdit(data) {
      this.driveEditState = data;
      this.dialogVisible = true;
    },
    getDriverById(id) {
      this.$router.push({
        path: "driver-edit",
        query: {
          id: id
        }
      });
      this.$notify({
        title: "Амжилттай",
        message: id + " " + "мэдээллийг засах",
        type: "success"
      });
    },
    getDriversByStatus() {
      service.getDriversByStatus().then(driverRes => {
        if (driverRes.status == "success") {
          this.driversByStatus.activeDrivers =
            driverRes.response.current_active_drivers;
          this.driversByStatus.inActiveDrivers =
            driverRes.response.current_inactive_drivers;
          this.driversByStatus.riders = driverRes.response.riders;
          this.driversByStatus.notVerifiedDrivers =
            driverRes.response.notVerifiedDrivers;
          this.driversByStatus.verifiedDrivers =
            driverRes.response.verifiedDrivers;
          this.driversByStatus.verifiedByHrDrivers =
            driverRes.response.verifiedByHrDrivers;
          this.driversByStatus.driversByGroup =
            driverRes.response.driversBygroup;
        } else {
          this.$notify({
            title: "Алдаа",
            message: driverRes.error.message,
            type: "warning"
          });
        }
      });
    },
    async getDrivers() {
      try {
        this.filterPayload.download = false;
        this.isDataLoading = true;
        let axiosResponse = null;
        if (this.filterPayload.is_disabled === "") {
          this.filterPayload.is_disabled = null;
        } else if (this.filterPayload.is_hr_verified === "") {
          this.filterPayload.is_hr_verified = null;
        }
        this.filterPayload.page = this.page;
        this.filterPayload.size = this.pageSize;
        {
          axiosResponse = await axios.post(
            process.env.VUE_APP_DRIVER_API + "admin/driver/getDriversNew",
            this.filterPayload
          );
        }
        if (axiosResponse.data.status !== "success") {
          throw new Error(axiosResponse.data.error.message);
        }
        this.driverList = axiosResponse.data.data.data;
        this.driverList.map(response => {
          if (response.is_hr_verified === 1) {
            response.is_hr_verified = true;
          } else {
            response.is_hr_verified = false;
          }
          if (response.is_disabled) {
            response.is_disabled = false;
          } else {
            response.is_disabled = true;
          }
        });
        this.pageCounts = axiosResponse.data.data.total;
        this.currentPage = axiosResponse.data.data.current_page;
        this.isDataLoading = false;
      } catch (err) {
        this.isDataLoading = false;
        this.$notify.error({
          title: "Алдаа",
          message: err.message
        });
      }
    },
    mArrayIncludes(base_array, searchArray) {
      return arrayIncludes(base_array, searchArray);
    },
    setPage(val) {
      this.page = val;
      this.getDrivers();
    },
    sizeChange(val) {
      this.pageSize = val;
      this.getDrivers();
    },
    searchFilter() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.page = 1;
        this.getDrivers();
      }, 1000);
    },
    searchunConfirmDriver() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.page = 1;
        this.getDrivers(2);
      }, 1000);
    },
    async download() {
      try {
        this.isDataLoading = true;
        let axiosResponse = null;
        this.filterPayload.download = true;
        this.filterPayload.start_date = this.filterPayload.start_date
          ? dayjs(this.filterPayload.start_date).format("YYYY-MM-DD 00:00:00")
          : "";
        this.filterPayload.end_date = this.filterPayload.end_date
          ? dayjs(this.filterPayload.end_date).format("YYYY-MM-DD 00:00:00")
          : this.filterPayload.end_date;
        if (this.filterPayload.is_disabled === "") {
          this.filterPayload.is_disabled = null;
        } else if (this.filterPayload.is_hr_verified === "") {
          this.filterPayload.is_hr_verified = null;
        }
        this.filterPayload.page = this.page;
        this.filterPayload.size = this.pageSize;
        {
          axiosResponse = await axios.post(
            process.env.VUE_APP_DRIVER_API + "admin/driver/getDriversNew",
            this.filterPayload
          );
        }
        if (axiosResponse.data.status !== "success") {
          throw new Error(axiosResponse.data.error.message);
        }
        let reportXlsx = XLSX.utils.json_to_sheet(axiosResponse.data.data);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, reportXlsx, "report");
        XLSX.writeFile(wb, "Жолоочийн жагсаалт.xlsx");
        this.isDataLoading = false;
      } catch (err) {
        this.isDataLoading = false;
        this.$notify.error({
          title: "Алдаа",
          message: err.message
        });
      }
    }
  }
};
</script>
